<template>
  <div>
    <div id="ticketingMap" />
    <v-dialog
      v-model="ticketing.viewTicketingDetailPopup"
      width="1600"
    >
      <ticketing-details
        :request="request"
        :filter-range-date="filterRangeDate"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import { log } from "@/constants.js";

export default {
  components: {
    TicketingDetails: () =>
      import('@/components/services/TicketingDetails')
  },
  props: {
    request: {
      type: Object,
      default: null
    },
    filterRangeDate: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapState(['ticketing'])
  },
  watch: {
    $route() {}
  },
  async mounted() {
  },
  methods: {
    ...mapActions([])
  }
};
</script>
